import React, { useCallback, useEffect, useState } from 'react'

import { useSnackbar } from 'notistack'
import { makeStyles } from '@material-ui/core/styles'

import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'

import { DialogActions, DialogContent, DialogTitle } from '../../util/dialogComponents'
import { Box, Button, ButtonGroup, Checkbox, Chip, Container, Dialog, FormControl, FormControlLabel, FormHelperText, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, useTheme } from '@material-ui/core'

const APPLICATIONS = [
    {
        sigla: 'PDV_ONLINE',
        label: 'PDV Online',
        url: 'https://pdv.sischef.com/',
    },
    {
        sigla: 'SISAGIL',
        label: 'Sisagil Gerencial',
        url: 'https://sistema.sischef.com/',
    },
]

const useStyles = makeStyles(theme => ({
    loginForm: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100vh',
        padding: theme.spacing(3),
    },

    typeBox: {
        padding: theme.spacing(),
    },

    chip: {
        '& + &': {
            marginLeft: theme.spacing(0.5),
        }
    },

    verticalChip: {
        '& + &': {
            marginTop: theme.spacing(0.5),
        }
    },
}))

function ButtonGroupIconButton(props) {
    const { disableElevation, fullWidth, variant, ...iconButtonProps } = props

    return <IconButton disableTouchRipple {...iconButtonProps} />
}

function NotificationChipList({ applications, vertical = false }) {
    const classes = useStyles()
    const className = vertical ? classes.verticalChip : classes.chip

    return (
        <>
            {applications.map(item => (
                <Chip
                    key={item.sigla}
                    label={item.label}
                    className={className}
                    color="primary" />
            ))}
        </>
    )
}

function TypeBox({ type }) {
    const theme = useTheme()
    const classes = useStyles()

    if (type === 'GRAVE') {
        return (
            <Box
                className={classes.typeBox}
                color={theme.palette.error.main}>
                GRAVE
            </Box>
        )
    }

    if (type === 'ATENCAO') {
        return (
            <Box
                className={classes.typeBox}
                color={theme.palette.warning.main}>
                ATENÇÃO
            </Box>
        )
    }

    return (
        <Box
            className={classes.typeBox}
            color={theme.palette.info.main}>
            INFO
        </Box>
    )
}

function LoginScreen({ onLoggedIn }) {
    const classes = useStyles()
    const { enqueueSnackbar } = useSnackbar()
    const [form, setForm] = useState({
        username: '',
        password: '',
    })

    function handleLogin(event) {
        event.preventDefault()

        if (form.username === 'parseint' && form.password === 'balanca123') {
            onLoggedIn(true)
            localStorage.setItem('logged-in', 'true')
            return
        }

        enqueueSnackbar('Login ou senha inválidos', { variant: 'error' })
    }

    function handleChange(event) {
        const { name, value } = event.target

        setForm(state => ({ ...state, [name]: value }))
    }

    return (
        <Container maxWidth="sm">
            <Box className={classes.loginForm}>
                <Typography variant="h4" style={{ textAlign: 'center' }}>
                    Cadastro de Notificações
                </Typography>
                <form onSubmit={handleLogin}>
                    <Grid container>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                name="username"
                                label="Usuário"
                                margin="normal"
                                variant="outlined"
                                value={form.username}
                                onInput={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                name="password"
                                type="password"
                                label="Senha"
                                margin="normal"
                                variant="outlined"
                                value={form.password}
                                onInput={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                fullWidth
                                type="submit"
                                color="primary"
                                variant="outlined"
                                endIcon={<ChevronRightIcon />}
                                onClick={handleLogin}>
                                Entrar
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Container>
    )
}

export function RegisterNotificacao() {
    const REACT_APP_API_URL = process.env.REACT_APP_API_URL
    const { enqueueSnackbar } = useSnackbar()

    const [form, setForm] = useState({})
    const [dialogOpen, setDialogOpen] = useState(false)
    const [notificacoes, setNotificacoes] = useState([])
    const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('logged-in'))

    const refresh = useCallback(() => {
        fetch(REACT_APP_API_URL + '/api/notificacao/list').then(data => {
            if (!data.ok) {
                throw Error(data.statusText)
            }

            return data.json()
        }).then(data => setNotificacoes(data)).catch(err => {
            console.log(err)
            enqueueSnackbar('Erro ao carregar notificações', { variant: 'error' })
        })
    }, [])

    useEffect(() => refresh(), [refresh])

    function update() {
        fetch(REACT_APP_API_URL + '/api/notificacao/update/' + form._id, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(form)
        }).then(data => {
            if (!data.ok) {
                throw Error(data.statusText)
            }

            return data.json()
        }).then(() => {
            refresh()
            setDialogOpen(false)
            enqueueSnackbar('Notificação atualizada com sucesso', { variant: 'success' })
        }).catch(err => {
            console.log(err)
            enqueueSnackbar('Erro ao atualizar notificação', { variant: 'error' })
        })
    }

    function confirm() {
        if (form._id) {
            update()
            return
        }

        fetch(REACT_APP_API_URL + '/api/notificacao/create', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(form)
        }).then(data => {
            if (!data.ok) {
                throw Error(data.statusText)
            }

            return data.json()
        }).then(() => {
            refresh()
            setDialogOpen(false)
            enqueueSnackbar('Notificação cadastrada com sucesso', { variant: 'success' })
        }).catch(err => {
            console.log(err)
            enqueueSnackbar('Erro ao cadastrar notificação', { variant: 'error' })
        })
    }

    function handleDelete(notificacao) {
        fetch(REACT_APP_API_URL + '/api/notificacao/delete/' + notificacao._id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(data => {
            if (!data.ok) {
                throw Error(data.statusText)
            }

            return data.json()
        }).then(() => {
            refresh()
            enqueueSnackbar('Notificação deletada com sucesso', { variant: 'success' })
        }).catch(err => {
            console.log(err)
            enqueueSnackbar('Erro ao deletar notificação', { variant: 'error' })
        })
    }

    function handleChange(event) {
        let { type, name, value, checked } = event.target

        if (name === 'applications') {
            value = APPLICATIONS.filter(item => value.includes(item.sigla))
        }

        setForm(state => ({
            ...state,
            [name]: type === 'checkbox' ? checked : value,
        }))
    }

    function handleOpen(notificacao) {
        const EMPTY_FORM = {
            text: '',
            title: '',
            type: 'INFO',
            applications: [...APPLICATIONS],
            link: '',
            active: true,
        }

        setForm(notificacao ? { ...notificacao } : { ...EMPTY_FORM })
        setDialogOpen(true)
    }

    function loggout() {
        localStorage.removeItem('logged-in')
        setIsLoggedIn(false)
    }

    if (!isLoggedIn) {
        return <LoginScreen onLoggedIn={setIsLoggedIn} />
    }

    return (
        <React.Fragment>
            <Container>
                <Box display="flex" justifyContent="space-between" py={2}>
                    <Typography variant="h4" component="h1">
                        Notificações
                    </Typography>
                    <Box display="flex" gridGap="8px">
                        <Button
                            color="secondary"
                            variant="outlined"
                            startIcon={<ExitToAppIcon />}
                            onClick={loggout}>
                            Sair
                        </Button>
                        <Button
                            color="primary"
                            variant="outlined"
                            startIcon={<AddIcon />}
                            onClick={() => handleOpen()}>
                            Nova Notificação
                        </Button>
                    </Box>
                </Box>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Ativo</TableCell>
                                <TableCell align="center">Tipo</TableCell>
                                <TableCell>Aplicações</TableCell>
                                <TableCell>Title</TableCell>
                                <TableCell>Descrição</TableCell>
                                <TableCell align="right">Ações</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {notificacoes.map((notificacao) => (
                                <TableRow key={notificacao._id}>
                                    <TableCell>{
                                        notificacao.active ? (
                                            <CheckBoxIcon color="primary" />
                                        ) : (
                                            <CheckBoxOutlineBlankIcon color="primary" />
                                        )
                                    }</TableCell>
                                    <TableCell align="center">
                                        <TypeBox type={notificacao.type} />
                                    </TableCell>
                                    <TableCell>{notificacao.applications?.length
                                        ? <NotificationChipList vertical applications={notificacao.applications} />
                                        : ' - '}
                                    </TableCell>
                                    <TableCell>{notificacao.title || '-'}</TableCell>
                                    <TableCell>{notificacao.text}</TableCell>
                                    <TableCell align="right">
                                        <ButtonGroup variant="text" size="small" style={{ backgroundColor: '#ffede3' }}>
                                            <ButtonGroupIconButton
                                                color="primary"
                                                onClick={() => handleOpen(notificacao)}>
                                                <EditIcon />
                                            </ButtonGroupIconButton>
                                            <ButtonGroupIconButton
                                                color="primary"
                                                onClick={() => handleDelete(notificacao)}>
                                                <DeleteIcon />
                                            </ButtonGroupIconButton>
                                        </ButtonGroup>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>

            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} maxWidth="sm">
                <DialogTitle onClose={() => setDialogOpen(false)}>
                    Cadastro de notificação
                </DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <FormControl variant="outlined" margin="normal">
                                <InputLabel>Tipo</InputLabel>
                                <Select
                                    name="type"
                                    label="Tipo"
                                    value={form.type}
                                    onChange={handleChange}>
                                    <MenuItem value="INFO">INFO</MenuItem>
                                    <MenuItem value="ATENCAO">ATENÇÃO</MenuItem>
                                    <MenuItem value="GRAVE">GRAVE</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl variant="outlined" margin="normal">
                                <InputLabel>Aplicações</InputLabel>
                                <Select
                                    multiple
                                    name="applications"
                                    label="Aplicações"
                                    style={{ minWidth: '150px' }}
                                    value={form.applications?.map(item => item.sigla) || []}
                                    onChange={handleChange}
                                    renderValue={(selected) => (
                                        <NotificationChipList applications={APPLICATIONS.filter(item => selected.includes(item.sigla))} />
                                    )}>
                                    {APPLICATIONS.map(item => (
                                        <MenuItem
                                            key={item.sigla}
                                            value={item.sigla}>
                                            {item.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>deixe vazio para todas as aplicações</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="title"
                                label="Título"
                                margin="normal"
                                variant="outlined"
                                value={form.title}
                                onInput={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                name="text"
                                label="Descrição"
                                margin="normal"
                                variant="outlined"
                                value={form.text}
                                onInput={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                name="link"
                                label="Link"
                                margin="normal"
                                variant="outlined"
                                value={form.link}
                                onInput={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                label="Ativo"
                                control={(
                                    <Checkbox
                                        color="primary"
                                        name="active"
                                        checked={form.active}
                                        onChange={handleChange} />
                                )}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={confirm}>
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}