import React, { useCallback, useEffect, useState } from 'react'

import InfoIcon from '@material-ui/icons/Info'
import CloseIcon from '@material-ui/icons/Close'
import ErrorIcon from '@material-ui/icons/Error'
import WarningIcon from '@material-ui/icons/Warning'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Button, Divider, Drawer, IconButton, List, ListItem, ListItemAvatar, ListItemText, ThemeProvider, Tooltip, Typography, createTheme } from '@material-ui/core'
import { ptBR } from 'date-fns/locale'
import { formatDistanceToNow, parseISO } from 'date-fns'

const PRIMARY = '#f10b5c'
const SECONDARY = '#747f86'

const lightTheme = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: PRIMARY,
        },
        secondary: {
            main: SECONDARY,
        },
    },
});

const darkTheme = createTheme({
    palette: {
        type: 'dark',
        primary: {
            main: PRIMARY,
        },
        secondary: {
            main: SECONDARY,
        },
        background: {
            paper: '#2a3a47',
        }
    },
});

const useStyles = makeStyles(() => ({
    mainWrapper: {
        width: '400px',
        maxWidth: '100vw',
        overflowX: 'hidden',
    },

    readButton: {
    },

    listItemWrapper: {
        position: 'relative',

        '& $readButton': {
            display: 'none',
        },

        '&:hover $readButton': {
            display: 'block',
        },

        '&:hover .notification-icon': {
            display: 'none',
        }
    }
}))

function typeIcon(type) {
    if (type === 'ATENCAO') {
        return <WarningIcon style={{ color: '#ffa726' }} />
    } else if (type === 'GRAVE') {
        return <ErrorIcon style={{ color: '#f44336' }} />
    } else if (type === 'INFO') {
        return <InfoIcon style={{ color: '#29b6f6' }} />
    }

    return <InfoIcon style={{ color: '#29b6f6' }} />
}

function NotificationItem({ notification, onMarkAsRead }) {
    const classes = useStyles()
    const formatedDate = formatDistanceToNow(parseISO(notification.createdAt), { locale: ptBR, addSuffix: true })

    function openLink() {
        window.open(notification.link, '_blank', 'noopener')
    }

    return (
        <Box className={classes.listItemWrapper} style={{ opacity: notification.read ? '0.5' : '1' }}>
            <ListItem alignItems="center">
                <ListItemAvatar>
                    <>
                        <Box className={!notification.read ? 'notification-icon' : ''}>
                            {typeIcon(notification.type)}
                        </Box>
                        {!notification.read && (
                            <Tooltip title="Marcar como lido">
                                <IconButton
                                    size="small"
                                    color="primary"
                                    className={classes.readButton}
                                    onClick={() => onMarkAsRead(notification)}>
                                    <VisibilityOffIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    </>
                </ListItemAvatar>
                <ListItemText
                    primary={!!notification.title && (
                        <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', gap: '8px' }}>
                            <Typography style={{ fontWeight: '600', flex: 1 }}>
                                {notification.title}
                            </Typography>
                            <Typography variant="caption">
                                {formatedDate}
                            </Typography>
                        </Box>
                    )}
                    secondary={notification.text}
                />
            </ListItem>
            {!!notification.link && (
                <Button
                    size="small"
                    color="primary"
                    variant="outlined"
                    onClick={openLink}
                    style={{ marginLeft: '72px', marginBottom: '16px' }}
                    endIcon={<OpenInNewIcon />}>
                    Saiba mais
                </Button>
            )}
            <Divider />
        </Box>
    )
}

function NotificationList({ messages, onMarkAsRead }) {
    const filteredMessages = [...messages]
    const readList = filteredMessages.filter(item => item.read)
    const unreadList = filteredMessages.filter(item => !item.read)

    if (!readList.length && !unreadList.length) {
        return (
            <Typography
                variant="body1"
                style={{ padding: '16px', textAlign: 'center' }}>
                Sem nenhuma notificação no momento
            </Typography>
        )
    }

    return (
        <List>
            {unreadList.map(item => (
                <NotificationItem
                    key={item._id}
                    notification={item}
                    onMarkAsRead={onMarkAsRead} />)
            )}
            {readList.map(item => (
                <NotificationItem
                    key={item._id}
                    notification={item}
                    onMarkAsRead={onMarkAsRead} />)
            )}
        </List>
    )
}

export function WidgetNotificacao() {
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const [origin, setOrigin] = useState()
    const [messages, setMessages] = useState([])
    const [username, setUsername] = useState('')
    const [application, setApplication] = useState('')
    const [theme, setTheme] = useState(lightTheme)

    const REACT_APP_API_URL = process.env.REACT_APP_API_URL

    const refresh = useCallback(() => {
        if (!username || !application) return

        fetch(REACT_APP_API_URL + `/api/notificacao/list-only-actives?username=${username}&application=${application}`)
            .then(data => data.json())
            .then(data => {
                setMessages(data)

                // envia para a página pai o numero de notificações não lidas
                window.parent.postMessage({
                    type: 'notification-number',
                    content: data.filter(item => !item.read).length,
                }, origin)
            }).catch(err => console.log(err))
    }, [username, application])

    function handleMarkAsRead(notification) {
        const json = {
            username,
            notificacao: notification._id
        }

        fetch(REACT_APP_API_URL + '/api/notificacao-status/create', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(json)
        }).then(data => data.json()).then(() => refresh()).catch(err => console.log(err))
    }

    useEffect(() => {
        if (username) {
            refresh()
        }
    }, [username, refresh])

    useEffect(() => {
        function handleMessage(event) {
            const { data } = event

            if (data.type === 'open-drawer') {
                setOpen(true)
            } else if (data.type === 'init') {
                setOrigin(event.origin)
                setUsername(data.username)
                setApplication(data.application)
                setTheme(data.theme === 'light' ? lightTheme : darkTheme)
            } else if (data.type === 'change-theme') {
                setTheme(data.theme === 'light' ? lightTheme : darkTheme)
            }
        }

        window.addEventListener('message', handleMessage)

        return () => window.removeEventListener('message', handleMessage)
    }, [])

    function close() {
        setOpen(state => !state)

        window.parent.postMessage({ type: 'close-drawer' }, origin)
    }

    return (
        <ThemeProvider theme={theme}>
            <style>{`body { background-color: transparent; }`}</style>
            <Drawer
                anchor="right"
                open={open}
                onClose={close}>
                <Box className={classes.mainWrapper}>
                    <Box sx={{ display: "flex", alignItems: "center", p: 2, gap: theme.spacing() }}>
                        <IconButton onClick={close}>
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h5">Notificações</Typography>
                    </Box>
                    <Box>
                        <Divider />
                        <NotificationList
                            messages={messages}
                            onMarkAsRead={handleMarkAsRead} />
                    </Box>
                </Box>
            </Drawer>
        </ThemeProvider>
    )
}