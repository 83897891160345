import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import CssBaseline from '@material-ui/core/CssBaseline';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import LoginForm from './components/login-form';
import Sobre from './components/app-about';
import { createTheme } from '@material-ui/core';
import GeneralSettings from './components/settings/general';
import SettingsPrinter from './components/settings/printer';
import HomePrintMonitor from './components/homePrintMonitor';
import { RegisterNotificacao } from './components/notificacao/RegisterNotificacao';
import { WidgetNotificacao } from './components/notificacao/WidgetNotificacao';

const TOKEN = "_accessKey";

const isAuthenticated = () => (
  localStorage.getItem(TOKEN) ? true : false
);



const theme = createTheme({
  dividerFullWidth: {
    margin: `5px 0 0 2px`,
  },
  dividerInset: {
    margin: `5px 0 0 2px`,
  },
  palette: {
    primary: {
      main: '#e65100',
    },
    secondary: {
      main: '#757575',
    },
  },
});


function App() {
  return (
    <div className="App">
      <RouterApp />
    </div>
  );
}


const RouterApp = () => {

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <CssBaseline />

        <Router>
          <Switch>
            <Route exact path="/" component={LoginForm} />
            <PrivateRoute exact path="/home" component={HomePrintMonitor} />
            <PrivateRoute exact path="/sobre" component={Sobre} />
            <PrivateRoute exact path="/configurarImpressoras" component={SettingsPrinter} />
            <PrivateRoute exact path="/configuracoes" component={GeneralSettings} />
            <Route exact path="/notificacao/embed" component={WidgetNotificacao} />
            <Route exact path="/notificacao/register" component={RegisterNotificacao} />
          </Switch>
        </Router>
      </SnackbarProvider>
    </ThemeProvider>
  )

}

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    isAuthenticated() ? (
      <Route
        {...props}
        component={Component}
      />
    ) : (
      <Redirect to={{ pathname: '/', state: { from: props.location } }} />
    )
  )} />
)


export default App;
